import React from 'react'

export default ({ color }) => (
  <svg
    width="85"
    height="71"
    viewBox="0 0 85 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.7913 0C43.2073 0 31.1523 9.283 21.9723 18.57C13.8013 18.101 3.53527 18.202 1.96127 19.906C-1.43173 23.577 0.257271 49.248 1.96127 50.692C3.33627 51.858 15.0143 52.811 23.5803 52.384C33.0583 61.667 44.7653 70.818 48.1633 70.818C52.2743 70.818 49.7083 63.507 49.7083 34.951C49.7073 7.782 51.9043 0 47.7913 0ZM83.9193 42.29C83.6303 41.756 80.8233 38.759 77.4283 35.278C80.8893 31.78 83.8073 28.692 84.1873 27.956C84.7333 26.902 81.7613 22.022 79.6173 23.385C78.5483 24.066 75.5923 26.852 72.3063 30.11C68.6633 26.498 65.3893 23.393 64.6293 23C63.5743 22.454 59.4833 26.367 60.0593 28.142C60.1973 28.569 63.2573 31.821 66.9383 35.537C63.4673 39.111 60.5943 42.195 60.3263 42.677C59.5543 44.063 62.4703 48.562 64.8963 47.248C65.4383 46.954 68.5173 44.068 72.0643 40.607C75.9313 44.348 79.3703 47.466 79.9193 47.432C81.7153 47.316 85.2323 44.72 83.9193 42.29Z"
      fill= {color}
    />
  </svg>
)
